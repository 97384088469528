<template>
<div>
  <b-sidebar id="sidebar-publishers" title="Sources" backdrop backdrop-variant="transparent" shadow width="95%">
    <div class="px-3 py-2">
    <b-container>
      <b-row>
        <b-col>
          <b-form-group horizontal>
            <b-input-group>
              <b-form-input v-model="searchSource" placeholder="Type to search source" />
                <b-btn @click="searchSource = ''">clear</b-btn>
            </b-input-group>
          </b-form-group>
          <b-form-checkbox-group
            id="checkbox-group-1"
            v-model="publishersSelected"
            :options="publishersOptionsShow"
            name="publishers"
            style="column-count: 2;"
            stacked
          ></b-form-checkbox-group>
        </b-col>
        <b-col>
          <b-container>
            <b-row>
              <b-col>
                <b-button variant="outline-primary" class="mb-4 mr-2" v-on:click="allPublishers">select all</b-button>
                <b-button variant="outline-primary" class="mb-4 mr-2" v-on:click="nonePublishers">clear all</b-button>
                <b-button v-b-toggle.sidebar-publishers variant="primary" class="mb-4 mr-2">close</b-button>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <h3>Quick add options</h3>
                <div class="mb-4">Use buttons below to add groups of sources to your selection</div>
              </b-col>
            </b-row>
            <b-row>
              <b-col v-if="$config.BUILD === 'full'">
                <div>
                  <div><strong>Expert-led curation</strong></div>
                  <div><b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="custom('level1')">RIC Level 1</b-button></div>
                  <div><b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="custom('level2')">RIC Level 2</b-button></div>
                  <div><b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="custom('accounting')">Accounting</b-button></div>
                  <div><b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="custom('clients')">Clients</b-button></div>
                </div>
                <div>
                  <div><strong>Core</strong></div>
                  <div><b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="custom('ukcore')">UK Core</b-button></div>
                  <div><b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="custom('eucore')">EU Core</b-button></div>
                  <div><b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="custom('intcore')">International Core</b-button></div>
                  <div><b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="custom('uscore')">US Core</b-button></div>
                  <div><b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="custom('dataprivacy')">Data privacy</b-button></div>
                  <div><b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="custom('lifescience')">Life sciences</b-button></div>
                </div>
              </b-col>
              <b-col>
                <div><strong>Countries</strong></div>
                <div v-for="country in countries" :key="country">
                  <b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="selectCountry(country)">{{ country }}</b-button>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </b-col>
      </b-row>
    </b-container>
    </div>
  </b-sidebar>
  <b-container fluid>
    <b-row>
      <b-col>
        <div>
          <b-form @submit="search">
            <b-form-group horizontal class="mt-4">
              <b-input-group>
                <b-form-input v-model="q" placeholder="Type to search" />
                  <b-btn :disabled="!q" @click="search">search</b-btn>
              </b-input-group>
              <b-button v-b-toggle.sidebar-publishers variant="outline-primary" class="mb-2">
                <span v-if="publishersSelected.length === 0">search all sources</span>
                <span v-else-if="publishersSelected.length === publishers">search all sources</span>
                <span v-else>search {{publishersSelected.length}} sources out of {{publishersOptions.length}}</span>
              </b-button>
            </b-form-group>
          </b-form>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col>
        <div v-if="!loadingNews">
          <h3>News</h3>
          <div>
            <b-form-group label="Sorting by">
              <b-form-radio-group
                v-model="sortingSelected"
                :options="sortingOptions"
                name="radio-sorting"
              ></b-form-radio-group>
            </b-form-group>
          </div>
          <div v-if="news.length > 0">
            <b-card class="mb-3" v-for="item in news" :key="item.id" :id="item.id">
              <publication
                :propItem="item"
                :key="item.id"
                :tagsProp=tags
                :username="user.username"
                :permissionAddArticle="permissionAddArticle"
                :permissionAddRisk="permissionAddRisk"
                :permissionAddTag="permissionAddTag"
                :permissionArticlesWip="permissionArticlesWip"
                :permissionDeletePublication="permissionDeletePublication"
                :permissionInsights="permissionInsights"
                :permissionReadTagsPanel="permissionReadTagsPanel"
                />
            </b-card>
          </div>
          <div v-else>
            <b-card>
              <p>No news found</p>
            </b-card>
          </div>
        </div>
        <div v-else>
          <div><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></div>
        </div>
      </b-col>
      <b-col>
        <div v-if="!loadingUnified">
          <div v-if="results.cases" class="mb-5">
            <div v-if="results.cases.length > 0">
              <h3>Cases</h3>
              <b-card>
                <div class="mb-1" v-for="item in results.cases" :key="'case-' + item.id">
                  <b-link :to="{ name: 'Case', params: { id: item.id } }">{{item.name}}</b-link>
                </div>
              </b-card>
            </div>
          </div>
          <div v-if="results.controls" class="mb-5">
            <div v-if="results.controls.length > 0">
              <h3>Controls</h3>
              <b-card>
                <div class="mb-1" v-for="item in results.controls" :key="'control-' + item.id">
                  <b-link :to="{ name: 'Control', params: { id: item.id } }">{{item.name}}</b-link>
                </div>
              </b-card>
            </div>
          </div>
          <div v-if="results.experts" class="mb-5">
            <div v-if="results.experts.length > 0">
              <h3>Experts</h3>
              <b-card>
                <div class="mb-1" v-for="item in results.experts" :key="'expert-' + item.id">
                  <b-link :to="{ name: 'ImpactExpert', params: { id: item.id } }">{{item.forename}} {{item.surname}}</b-link>
                </div>
              </b-card>
            </div>
          </div>
          <div v-if="results.events" class="mb-5">
            <div v-if="results.events.length > 0">
              <h3>Events</h3>
              <b-card>
                <div class="mb-1" v-for="item in results.events" :key="'event-' + item.id">
                  <b-link :to="{ name: 'Event', params: { id: item.id } }">{{item.name}}</b-link>
                </div>
              </b-card>
            </div>
          </div>
          <div v-if="results.initiatives" class="mb-5">
            <div v-if="results.initiatives.length > 0">
              <h3>Initiatives</h3>
              <b-card>
                <div class="mb-1" v-for="item in results.initiatives" :key="'initiative-' + item.id">
                  <b-link :to="{ name: 'ImpactInitiative', params: { id: item.id } }">{{item.name}}</b-link>
                </div>
              </b-card>
            </div>
          </div>
          <div v-if="results.articles" class="mb-5">
            <div v-if="results.articles.length > 0">
              <h3>Insights</h3>
              <b-card>
                <div class="mb-1" v-for="item in results.articles" :key="'article-' + item.id">
                  <b-link :to="{ name: 'Article', params: { id: item.id } }">{{item.title}}</b-link>
                </div>
              </b-card>
            </div>
          </div>
          <div v-if="results.obligations" class="mb-5">
            <div v-if="results.obligations.length > 0">
              <h3>Obligations</h3>
              <b-card>
                <div class="mb-1" v-for="item in results.obligations" :key="'obligation-' + item.id">
                  <b-link :to="{ name: 'ImpactObligation', params: { id: item.id } }">{{item.name}}</b-link>
                </div>
              </b-card>
            </div>
          </div>
          <div v-if="results.regulations" class="mb-5">
            <div v-if="results.regulations.length > 0">
              <h3>Regulations</h3>
              <b-card>
                <div class="mb-1" v-for="item in results.regulations" :key="'regulation-' + item.id">
                  <b-link :to="{ name: 'RegDecompRegulation', params: { id: item.id } }">{{item.name_short}}</b-link>
                </div>
              </b-card>
            </div>
          </div>
          <div v-if="results.risks" class="mb-5">
            <div v-if="results.risks.length > 0">
              <h3>Risks</h3>
              <b-card>
                <div class="mb-1" v-for="item in results.risks" :key="'risk-' + item.id">
                  <b-link :to="{ name: 'Risk', params: { id: item.id } }">{{item.name}}</b-link>
                </div>
              </b-card>
            </div>
          </div>
          <div v-if="results.topics" class="mb-5">
            <div v-if="results.topics.length > 0">
              <h3>Topics</h3>
              <b-card>
                <div class="mb-1" v-for="item in results.topics" :key="'topic-' + item.id">
                  <b-link :to="{ name: 'Topic', params: { id: item.id } }">{{item.name}}</b-link>
                </div>
              </b-card>
            </div>
          </div>
        </div>
        <div v-else>
          <div><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import _ from 'lodash'
import ac from '../libs/accesscontrol'
import Publication from '@/components/PublicationNew'
import sourcesGroups from '../libs/sourcesGroups.js'

export default {
  components: {
    Publication
  },
  computed: {
    publishersOptionsShow: function () {
      let result = this.publishersOptions
      if (this.searchSource.length > 0) {
        result = _.filter(result, x => {
          return x.text.toUpperCase().includes(this.searchSource.toUpperCase())
        })
      }
      return result
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: async function () {
    document.title = "KPMG Regulatory Horizon: Search"
    this.permissionAddArticle = ac.can(this.user.acgroups).createAny('article').granted
    this.permissionAddRisk = ac.can(this.user.acgroups).createAny('marrisk').granted
    this.permissionAddTag = ac.can(this.user.acgroups).createAny('newstag').granted
    this.permissionArticlesWip = ac.can(this.user.acgroups).createAny('articleWorkflowEdit').granted
    this.permissionDeletePublication = ac.can(this.user.acgroups).deleteAny('publication').granted
    this.permissionInsights = true
    this.permissionReadAnalytics = ac.can(this.user.acgroups).readAny('analytics').granted
    this.permissionReadTagsPanel = ac.can(this.user.acgroups).readAny('tagspanel').granted
    this.unifiedParams = {
      searchTerm: this.q,
      articles: true,
      cases: ac.can(this.user.acgroups).readAny('case').granted,
      controls: ac.can(this.user.acgroups).readAny('control').granted,
      experts: true,
      events: true,
      initiatives: true,
      obligations: ac.can(this.user.acgroups).readAny('impactobligation').granted,
      regulations: ac.can(this.user.acgroups).readAny('regulation').granted,
      risks: ac.can(this.user.acgroups).readAny('marrisk').granted,
      topics: true
    }
    if (this.$config.BUILD === 'external') {
      this.permissionAddRisk = false
      this.permissionAddTag = false
      this.permissionDeletePublication = false
      this.permissionInsights = ac.can(this.user.acgroups).readAny('article').granted
      this.permissionReadAnalytics = false
      this.permissionReadTagsPanel = false
      this.unifiedParams = {
        searchTerm: this.q,
        articles: ac.can(this.user.acgroups).readAny('article').granted,
        events: true,
        risks: ac.can(this.user.acgroups).readAny('marrisk').granted,
        topics: true
      }
    }
    try {
      let apiName = 'cosmos'
      let path = `/tags`
      this.tags = await this.$Amplify.API.get(apiName, path)
      this.sources = await this.$Amplify.API.get(apiName, '/sources')
      this.publishers = _.uniq(this.sources.map(source => source.publisher)).sort()
      this.publishers.forEach(element => this.publishersOptions.push({ text: element, value: element }))
      this.countries = _.uniq(this.sources.map(source => source.countryName)).sort()
    } catch (e) {
      this.$logger.warn('error getting events for a day', e)
    }
    if (this.$route.query.o) {
      if (this.$route.query.o === 'time') {
        this.sortingSelected = 'time'
      } else {
        this.sortingSelected = 'rank'
      }
    } else {
      const options = window.localStorage.getItem('frmCloudSearchSorting')
      if (options) {
        this.sortingSelected = JSON.parse(options)
      }
    }
    this.load()
  },
  data () {
    return {
      authToken: '',
      countries: [],
      gridLoaderColor: 'black',
      gridLoaderSize: '10px',
      loadingNews: true,
      loadingUnified: true,
      news: [],
      permissionAddArticle: false,
      permissionAddRisk: false,
      permissionAddTag: false,
      permissionArticlesWip: false,
      permissionDeletePublication: false,
      permissionReadAnalytics: false,
      permissionReadTagsPanel: false,
      publishers: [],
      publishersOptions: [],
      publishersSelected: [],
      results: {},
      searchSource: '',
      sortingOptions: [
        { text: 'By rank', value: 'rank' },
        { text: 'By date', value: 'time' }
      ],
      sortingSelected: 'rank',
      sources: [],
      q: '',
      tags: [],
      unifiedParams: null
    }
  },
  methods: {
    allPublishers: function () {
      this.publishersSelected = this.publishers
    },
    custom: function (selection) {
      const groups = sourcesGroups
      this.publishersSelected = _.uniq(_.concat(this.publishersSelected, groups[selection]))
    },
    load: async function () {
      this.loadingNews = true
      this.loadingUnified = true
      this.q = this.$route.params.q
      this.$logger.debug('load started')
      try {
        const params = {
          body: {
            searchTerm: this.q,
            articles: true,
            cases: ac.can(this.user.acgroups).readAny('case').granted,
            controls: ac.can(this.user.acgroups).readAny('control').granted,
            experts: true,
            events: true,
            initiatives: true,
            obligations: ac.can(this.user.acgroups).readAny('impactobligation').granted,
            regulations: ac.can(this.user.acgroups).readAny('regulation').granted,
            risks: ac.can(this.user.acgroups).readAny('marrisk').granted,
            topics: true
          }
        }
        this.results = await this.$Amplify.API.post('cosmos', '/searchunified', params)
        this.loadingUnified = false
        if (Number.isInteger(Number(this.q))) {
          let response = await this.$Amplify.API.get('cosmos', `/news/item/${this.q}`)
          this.$logger.debug(response)
          this.news = [response]
          this.loadingNews = false
        } else if (this.q.match(/^(http|https):\/\//)) {
          let response = await this.$Amplify.API.post('cosmos', `/search/link`, { body: { link: this.q } })
          this.$logger.debug(response)
          this.news = response
          this.loadingNews = false
        } else {
          let publishers = false
          if (this.publishersSelected.length > 0 && this.publishersSelected.length < this.publishers.length) {
            publishers = this.publishersSelected
          }
          const params = {
            body: {
              q: this.q,
              o: this.sortingSelected,
              publishers: publishers
            }
          }
          let response = await this.$Amplify.API.post('cosmos', '/search', params)
          this.$logger.debug(response)
          this.news = response
          this.loadingNews = false
        }
        this.$stat.log({ page: 'search', action: 'searched', payload: { term: this.q, sorting: this.sortingSelected } })
      } catch (e) {
        this.$logger.warn('saved ERROR: ' + e)
      }
    },
    nonePublishers: function () {
      this.publishersSelected = []
    },
    search: function (evt) {
      evt.preventDefault()
      if (this.q !== this.$route.params.q || this.sortingSelected !== this.$route.query.o) {
        this.$router.push({ name: 'Search', params: { q: this.q }, query: { o: this.sortingSelected } })
      } else {
        this.load()
      }
    },
    selectCountry: function (country) {
      const publishers = []
      _.each(this.sources, source => {
        if (source.countryName === country) {
          publishers.push(source.publisher)
        }
      })
      this.publishersSelected = _.uniq(_.concat(this.publishersSelected, _.uniq(publishers)))
    },
    updateSorting: function () {
      this.$router.push({ name: 'Search', params: { q: this.q }, query: { o: this.sortingSelected } })
      window.localStorage.setItem('frmCloudSearchSorting', JSON.stringify(this.sortingSelected))
    }
  },
  watch: {
    '$route.params.q': 'load',
    '$route.query.o': 'load',
    'sortingSelected': 'updateSorting'
  }
}
</script>

<style>
</style>
